import React from "react";
import Home from "./pages/home/Home";
import Wholesaler from "./pages/wholesaler/wholesaler-home"
import Quiz from "./pages/quiz/quiz-home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/cbd">
          <Home />
        </Route>
        <Route exact path="/wholesaler">
          <Wholesaler />
        </Route>
        <Route exact path="/">
          <Quiz />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;