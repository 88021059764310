import React from "react";
import styled, { keyframes } from "styled-components";

const lodingBars = () => keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.6);
  }
  20% {
    transform: scaleY(1.2);
  }
`;

const LoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: ${props => props.show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    z-index: 15;

    .loading-bars {
      width: 48px;

      span {
        float: left;
        width: 8px;
        height: 16px;
        background-color: #ed3f3a;
        margin: 0 2px;
        animation: ${lodingBars} 1.2s infinite ease-in-out;

        &.bar2 {
          animation-delay: -1.1s;
        }
        &.bar3 {
          animation-delay: -1s;
        }
        &.bar4 {
          animation-delay: -0.9s;
        }
      }
    }
`;

const Loader = ({ show }) => {
  return (
    <LoaderWrapper show={show}>
      <div className="loading-bars">
        <span className="bar1" />
        <span className="bar2" />
        <span className="bar3" />
        <span className="bar4" />
      </div>
    </LoaderWrapper>
  );
};

export default Loader;
