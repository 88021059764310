import React, { useState, useEffect, Fragment } from "react";
import CryptoJS from "crypto-js";
import Loader from "../common-components/Loader";
import { HomeWrapper } from "./quiz-home.styles";

let API_URL = "https://dev-api.vajro.com/v4/survey/form?id=jp0FrXbB&email=";

// const RightArrow = () => {
//   return (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L13.5858 8L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893Z"
//         fill="rgb(47,47,47)"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M16 8C16 7.44772 15.5523 7 15 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H15C15.5523 9 16 8.55229 16 8Z"
//         fill="rgb(47,47,47)"
//       />
//     </svg>
//   );
// };

const Home = () => {
  const [getCampaignData, setCampaignData] = useState({});
  // const [positive, setPositive] = useState(false);
  // const [negative, setNegative] = useState(false);
  // const [neutral, setNeutral] = useState(false);

  const download = () => {
    window.open(
      `https://vajro-growth.herokuapp.com/survey/export?email=${parsed_email_qs.email}`
    );
  };

  function parse_query_string(query) {
    let vars = query.split("&");
    let query_string = {};
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        let arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }

  let query_string = window.location.search.substring(1);
  let parsed_email_qs = parse_query_string(query_string);

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${CryptoJS.SHA256(
      API_URL + parsed_email_qs.email
    ).toString()}`,
  });

  let requestDetails = {
    method: "GET",
    headers,
  };

  useEffect(() => {
    if (Object.keys(getCampaignData).length > 0) {
      return;
    } else {
      fetch(API_URL + parsed_email_qs.email, requestDetails)
        .then((response) => response.json())
        .then((resData) => {
          const dataValue = resData;

          // for (let i = 0; i < dataValue.data.length; i++) {
          //   if (
          //     dataValue.data[i].feedback === "positive" &&
          //     dataValue.data[i].content.length > 0
          //   ) {
          //     setPositive(true);
          //     break;
          //   }
          // }
          // for (let i = 0; i < dataValue.data.length; i++) {
          //   if (
          //     dataValue.data[i].feedback === "negative" &&
          //     dataValue.data[i].content.length > 0
          //   ) {
          //     setNegative(true);
          //     break;
          //   }
          // }
          // for (let i = 0; i < dataValue.data.length; i++) {
          //   if (
          //     dataValue.data[i].feedback === "neutral" &&
          //     dataValue.data[i].content.length > 0
          //   ) {
          //     setNeutral(true);
          //     break;
          //   }
          // }

          setCampaignData(dataValue);
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);

  return (
    <Fragment>
      {getCampaignData.data ? (
        <HomeWrapper>
          <div className="cbd">
            <div id="hide-pdf">
              <div className="bg">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12  pt-5">
                      <h2 className="header-content">Your score</h2>
                      <p></p>
                      <h1 className="score-txt">
                        {getCampaignData.score} <span>/ 100</span>{" "}
                      </h1>
                    </div>

                    <div className="col-lg-12 col-md-12  col-sm-12">
                      <div className="score-dynamic-content">
                        {(() => {
                          if (getCampaignData.score > 70) {
                            return (
                              <div>
                                <h2>Well, what’re you waiting for?</h2>
                                <p>
                                  Your store is missing out on a lot of
                                  potential customers and revenue without a
                                  mobile app. Optimize the shopping experience
                                  for your customers with a mobile app and watch
                                  your revenue & conversion rate grow 3x.
                                </p>
                              </div>
                            );
                          } else if (
                            getCampaignData.score > 50 &&
                            getCampaignData.score < 70
                          ) {
                            return (
                              <div>
                                <h5>
                                  This is the right time for you to get your
                                  store a mobile app experience!
                                </h5>
                                <p>
                                  A mobile app would help you double your
                                  conversions and generate 40% more revenue!
                                  Getting a mobile app would be the best way to
                                  improve the shopping experience you offer, for
                                  the kind of products you sell and engagement
                                  your store gets
                                </p>
                              </div>
                            );
                          } else if (
                            getCampaignData.score > 30 &&
                            getCampaignData.score < 50
                          ) {
                            return (
                              <div>
                                <h5>
                                  This would be the perfect time to get a mobile
                                  app and boost your ROI!
                                </h5>
                                <p>
                                  Improve your online presence and build a loyal
                                  customer base with a sleek mobile app for your
                                  Shopify store to generate more revenue in the
                                  longer run.
                                </p>
                              </div>
                            );
                          } else {
                            return (
                              <div>
                                <h5>
                                  Perhaps your Shopify store does not need a
                                  mobile app right now. Either your products are
                                  not regular purchases or you haven’t created
                                  enough engagement among your customers, and
                                  are not ready for a mobile app just yet.
                                </h5>
                                <p>
                                  However, if you believe that your products are
                                  meant for regular purchases, getting a mobile
                                  app might help you reach the right customers
                                  and drive more revenue!
                                </p>
                              </div>
                            );
                          }
                        })()}

                        <button className="btn download-btn" onClick={download}>
                          Download your result
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pdf-generating">
              <div className="bg">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12  pt-5">
                    <h1 className="score-txt">{getCampaignData.score}</h1>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="score-dynamic-content">
                      {(() => {
                        if (getCampaignData.score > 70) {
                          return (
                            <div>
                              <h3 style={{fontWeight:"bold"}}>Well, what’re you waiting for?</h3>
                              <p>
                                Your store is missing out on a lot of potential
                                customers and revenue without a mobile app.
                                Optimize the shopping experience for your
                                customers with a mobile app and watch your
                                revenue & conversion rate grow 3x.
                              </p>
                            </div>
                          );
                        } else if (
                          getCampaignData.score > 50 &&
                          getCampaignData.score < 70
                        ) {
                          return (
                            <div>
                              <h5>
                                This is the right time for you to get your store
                                a mobile app experience!
                              </h5>
                              <p>
                                A mobile app would help you double your
                                conversions and generate 40% more revenue!
                                Getting a mobile app would be the best way to
                                improve the shopping experience you offer, for
                                the kind of products you sell and engagement
                                your store gets
                              </p>
                            </div>
                          );
                        } else if (
                          getCampaignData.score > 30 &&
                          getCampaignData.score < 50
                        ) {
                          return (
                            <div>
                              <h5>
                                This would be the perfect time to get a mobile
                                app and boost your ROI!
                              </h5>
                              <p>
                                Improve your online presence and build a loyal
                                customer base with a sleek mobile app for your
                                Shopify store to generate more revenue in the
                                longer run.
                              </p>
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              <h5>
                                Perhaps your Shopify store does not need a
                                mobile app right now. Either your products are
                                not regular purchases or you haven’t created
                                enough engagement among your customers, and are
                                not ready for a mobile app just yet.
                              </h5>
                              <p>
                                However, if you believe that your products are
                                meant for regular purchases, getting a mobile
                                app might help you reach the right customers and
                                drive more revenue!
                              </p>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="third-fold p-5">
              <h1 style={{textAlign:"center",fontWeight:"bold"}}>Here's what your score means!</h1>
                <div>
                  {
                    <div className="third-fold-content-wrapper" style={{paddingTop:"25px"}}>
                    
                      <div className={`third-fold-content`}>
                        <div className="background"></div>
                        <div className="left-col">
                          <figure
                            style={{
                              backgroundImage: `url('https://res.cloudinary.com/vajrohq/image/upload/v1658090513/admin_console/vajro-website/quiz/Rectangle_5.png')`,
                            }}
                          />
                        </div>
                        <div className="right-content-list">
                          <h3>Is your store on Shopify?</h3>
                          <ul style={{marginBottom:"40px"}}> 
                            {(getCampaignData.data.slice(1, 2) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                          <h3>What kinda products do you sell?</h3>
                          <ul>
                            {(getCampaignData.data.slice(2, 3) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    <div className="third-fold-content-wrapper" style={{paddingBottom:"150px",paddingTop:"85px"}}>
                      <div className={`third-fold-content`}>
                        <div className="background2"></div>
                        <div className="left-col">
                          <figure
                            style={{
                              backgroundImage: `url('https://res.cloudinary.com/vajrohq/image/upload/v1658090509/admin_console/vajro-website/quiz/Rectangle_7_1.png')`,
                            }}
                          />
                        </div>
                        <div className="right-content-list">
                          <h3>
                            Are your products ones customers buy again and
                            often?
                          </h3>
                          <ul style={{marginBottom:"40px"}}>
                            {(getCampaignData.data.slice(3, 4) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                          <h3>Do stores similar to yours have mobile apps?</h3>
                          <ul>
                            {(getCampaignData.data.slice(4, 5) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    <div className="third-fold-content-wrapper">
                      <div className={`third-fold-content`}>
                        <div className="background"></div>
                        <div className="left-col">
                          <figure
                            style={{
                              backgroundImage: `url('https://res.cloudinary.com/vajrohq/image/upload/v1658090511/admin_console/vajro-website/quiz/Rectangle_9_1.png')`,
                            }}
                          />
                        </div>
                        <div className="right-content-list">
                          <h3>
                            How many customers visit your online store on an
                            average in a month in terms of sessions?
                          </h3>
                          <ul style={{marginBottom:"40px"}}>
                            {(getCampaignData.data.slice(5, 6) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                          <h3>
                            How many of your customers visit your store from
                            mobile devices?
                          </h3>
                          <ul>
                            {(getCampaignData.data.slice(6, 7) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    <div className="third-fold-content-wrapper" style={{paddingTop:"100px",paddingBottom:"70px"}}>
                      <div className={`third-fold-content`}>
                        <div className="background2"></div>
                        <div className="left-col">
                          <figure
                            style={{
                              backgroundImage: `url('https://res.cloudinary.com/vajrohq/image/upload/v1658090513/admin_console/vajro-website/quiz/Rectangle_11.png')`,
                            }}
                          />
                        </div>
                        <div className="right-content-list">
                          <h3>
                            What device do you think your customers visit your
                            store more from?
                          </h3>
                          <ul style={{marginBottom:"40px"}}>
                            {(getCampaignData.data.slice(7, 8) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                          <h3>
                            How many of your customers do you think buy from
                            your store more than once?
                          </h3>
                          <ul>
                            {(getCampaignData.data.slice(8, 9) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    <div className="third-fold-content-wrapper" style={{padding:"0 0 50px 0"}}>
                      <div className={`third-fold-content`}>
                        <div className="background"></div>
                        <div className="left-col">
                          <figure
                            style={{
                              backgroundImage: `url('https://res.cloudinary.com/vajrohq/image/upload/v1658090500/admin_console/vajro-website/quiz/Rectangle_13.png')`,
                            }}
                          />
                        </div>
                        <div className="right-content-list">
                          <h3>
                            Let’s talk Social media! How many followers do you
                            have on your social media account?
                          </h3>
                          <ul style={{marginBottom:"40px"}}>
                            {(getCampaignData.data.slice(9, 10) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                          <h3>
                            Do you think sending mobile push notifications can
                            get more eyes on your store?
                          </h3>
                          <ul>
                            {(getCampaignData.data.slice(12, 13) || []).map(
                              (item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {<li>{item.content}</li>}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div className="footer-content">
                {" "}
                <h3>Still not sure if you need a Mobile App?</h3>
                <p>
                  Numerous Shopify stores have adopted the mobile first strategy
                  and have seen a staggering increase in ROI!
                  <br />
                  You’re one step closer to boosting your revenue by 3x!
                </p>
                <a href="https://apps.shopify.com/vajro" style={{textDecoretion:"none"}}><button className="btn pink-btn">
                  Start your 60-Day Free Trial
                </button></a> 
                <hr />
              </div>
            </div>
          </div>
        </HomeWrapper>
      ) : (
        <Loader show />
      )}
    </Fragment>
  );
};

export default Home;
