import styled from 'styled-components';
import { AppImage } from '../../helpers/AppImageConstants';

import { xs, sm, md } from '../styled-components/device';
import { theme } from '../styled-components/theme';

export const HomeWrapper = styled.div`
    #hide-pdf{

        .bg{
            background-image: url('${AppImage.background}');
            background-repeat: no-repeat;
            background-size: 100% 650px;
           
            @media (max-width: 2560px) {
                background-size: 100% 700px;
                
            }
            @media (max-width: 1024px) {
                background-size: 100% 650px;
                
            }
            @media (max-width:768px) {
                background-size: 100% 500px;
                
            }
            @media (max-width:425px) {
                background-size: 100% 265px;
                
            }

        }
       
       
        }
        

        .row{
            margin:0px;
            .logo{
                img{
                    margin: 30px auto 10px 75px;
                    @media (max-width: 768px) {
                        margin-left:18px; 
                        
                    }
                    @media (max-width: 425px) {
                        margin-left:0px; 
                        
                    }
                }
                
                @media (max-width: 425px) {
                    text-align:center;
                    
                }
                
            }
            .score-text-wrapper{
                margin-bottom:100px;
                margin-top:-3pc;              
                @media (max-width: 1024px) {

                    margin-bottom:104px;

                    img{
                        width:400px;
                    }
                    
                    }
                @media (max-width: 768px) {
                    margin-bottom:160px;
                       img{
                        width:300px;
                       }
                      }
                @media (max-width: 425px) {
                    
                    img{
                        width: 80%;
                        margin-top: 70px;
                    }
                    }
                h1{
                    margin-top:-330px;
                    margin-bottom:70px;
                    color:#fff;
                    font-size: 21px;
                    font-weight: 600;
                    @media (max-width: 2560px) {
                        margin-top: -290px;
                      }
                    @media (max-width: 768px) {
                        margin-top: -232px;
                        font-size: 21px;
                        font-weight: 600;
                        margin-bottom: 55px;
                      }
                    @media (max-width: 425px) {
                        margin-top: -240px;
                        font-size: 21px;
                        font-weight: 600;
                        
                    }
                    @media (max-width: 375px) {
                        margin-top: -200px;
                        margin-bottom: 45px;
                        font-size: 18px;
                        font-weight: 600;
                        
                    }
                }
                span{
                 color:#fff;
                 font-size: 152px;;
                 font-weight:600;
                 @media (max-width: 768px) {
                    
                    font-size: 140px;
                    
                  }
                  @media (max-width: 375px) {
                    
                    font-size: 100px;
                    
                  }
                }
            }
            .iphone{
                margin-top:-400px;
                @media (max-width: 1024px) {
                   margin-top:-350px;
                }
                @media (max-width: 768px) {
                         margin-top: -325px;
                        text-align:center;
                       }
                    @media (max-width: 425px) {
                        margin-top: -77px;
                        margin-bottom: 34px;
                    }
            }
            .score-dynamic-content{
                p{
                    font-weight:bold;
                }
                h2{
                    padding-left:50px;
                    padding-right:50px;
                    font-size: 34px;
                    font-weight: bold;
                    line-height: 1.32;
                }
                
                color:#fff;
                @media (max-width: 767px) {
                    color:#3b3b3b;
                    margin-top:50px; 
                }
                @media (max-width: 991px) {
                    
                    h2{
                        font-size:19px;
                        margin-top:-85px;
                    }
                }
                
            }
            .footer-wrapper{
                
                text-align:center;
                background-color:#f7f7f7;
                margin-top: 230px;
                padding-bottom:60px;
                @media (max-width: 2560px){
                    margin-top: 290px;
                }
                @media (max-width: 1024px){
                    margin-top: 230px;
                }
                @media (max-width: 425px) {
                    margin-top:50px; 
                }

                
                h1{
                  margin-top:80px;  
                  font-size: 40px;
                  font-stretch: normal;
                  color: #3b3b3b;
                  @media (max-width: 767px) {
                    margin-top:50px;  
                    font-size: 24px;
                }

                }
               
               .border-pink{
                 margin:30px auto;
                 width:120px;
                 background-color:#273b54;
                 height:5px;
               }
                p{
                    
                   padding:0px 150px;
                    font-size: 18px;
                    line-height: 1.56;
                    letter-spacing: 0.18px;
                    color: #3b3b3b;
                    @media (max-width: 767px) {
                        padding:0px 10px;
                        font-size: 12px;
                    }

                }
                .btn-call{
                    border: none;
                    border-radius: 35px;
                    box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 16%);
                    background-color: #22334a;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    margin: 10px;
                    padding: 18px 39px;
                    ${sm} {
                        &:hover {
                            transform: translateY(-3px);
                        }
                    };
                }
                .btn-mobile-app{
                    margin: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    border-radius: 35px;
                    box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 16%);
                    background-color: #22334a;
                    color: #fff;
                    padding: 18px 22px;
                    ${sm} {
                        &:hover {
                            transform: translateY(-3px);
                        }
                    };
                }
            }
        }
        
    }

    .pdf-generating{
       display:none;
   }
   
    .main-header{
        background-image: url('${AppImage.background}');
        background-repeat: no-repeat;
        background-size: 100% 748px;
        @media (max-width: 1024px) {
            background-size: 100% 650px;
            
        }
        @media (max-width:768px) {
            background-size: 100% 500px;
            
        }
        @media (max-width:425px) {
            background-size: 100% 265px;
            
        }
        .row{
            margin:0px;
            .space{
                margin-bottom: 176px;
            }
            .logo{
                img{
                    margin: 30px auto 10px 75px;
                    @media (max-width: 768px) {
                        margin-left:18px; 
                        
                    }
                    @media (max-width: 425px) {
                        margin-left:0px; 
                        
                    }
                }
                
                @media (max-width: 425px) {
                    text-align:center;
                    
                }
                
            }
            .score-text-wrapper{
                @media (max-width: 768px) {
                     margin-bottom: 80px;

                    }
               
                @media (max-width: 425px) {
                    
                    img{
                        width: 80%;
                        margin-top: 70px;
                    }
                    }
                h1{
                    margin-top:-330px;
                    margin-bottom:70px;
                    color:#fff;
                    font-size: 21px;
                    font-weight: 600;
                    @media (max-width: 2560px) {
                        margin-top: -290px;
                      }
                    @media (max-width: 768px){
                        margin-top: -232px;
                        margin-bottom: 55px;
                    }
                    @media (max-width: 425px) {
                        margin-top: -240px;
                        font-size: 21px;
                        font-weight: 600;
                        
                    }
                    @media (max-width: 375px) {
                        margin-top: -200px;
                        margin-bottom: 45px;
                        font-size: 18px;
                        font-weight: 600;
                        
                    }
                }
                span{
                 color:#fff;
                 font-size: 152px;
                 font-weight: 600;
                 @media (max-width: 768px) {
                    
                    font-size: 140px;
                    
                  }
                  @media (max-width: 375px) {
                    
                    font-size: 100px;
                    
                  }
                }
            }
            .iphone{
                margin-top:-400px;
                @media (max-width: 1024px) {
                   margin-top:-350px;
                }
                @media (max-width: 768px) {
                         margin-top: -325px;
                        text-align:center;
                       }
                    @media (max-width: 425px) {
                        margin-top: -77px;
                        margin-bottom: 34px;
                    }
            }
            .score-dynamic-content{
                
                color:#fff;
                @media (max-width: 767px) {
                    color:#3b3b3b;
                    margin-top:50px; 
                }
                @media (max-width: 991px) {
                    
                    h2{
                        font-size:19px;
                        margin-top:-5px;
                    }
                }
                
            }
            .footer-wrapper{
                
                text-align:center;
                background-color:#f7f7f7;
                margin-top:50px; 
                padding-bottom:60px;
                
                h1{
                  margin-top:80px;  
                  font-size: 40px;
                  font-weight: 500;
                  font-stretch: normal;
                  color: #3b3b3b;

                }
               
               .border-pink{
                 margin:30px auto;
                 width:200px;
                 background-color:#e72369;
                 height:5px;
               }
                p{
                    
                   padding:0px 150px;
                    font-size: 18px;;
                    line-height: 1.56;
                    letter-spacing: 0.18px;
                    

                }
                .btn-call{
                    border:none;
                    padding: 23px 80px 22px;
                    border-radius: 20px;
                    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
                    background-color: #ffffff;
                    font-size: 16px;
                    font-weight: 600;
                    color: #ea2369;
                    margin:10px;
                    ${sm} {
                        &:hover {
                            transform: translateY(-3px);
                        }
                    };
                }
                .btn-mobile-app{
                    margin:10px;
                    font-size: 16px;
                    font-weight: 600;
                    border:none;
                    padding: 23px 29px 22px 30px;
                    border-radius: 20px;
                    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
                    background-image: linear-gradient(to left, #ed2369, #bb2369);
                    color:#fff;
                    ${sm} {
                        &:hover {
                            transform: translateY(-3px);
                        }
                    };
                }
            }
        }
       
    }
    

    .btn-wrapper {
        margin-top: 20px;
        .btn.btn-primary {
            font-weight: bold;
            font-size: 15px;
            padding: 13px 41px 13px 45px;
            border-radius: 39px;
            box-shadow: 3px 3px 6px 0 rgba(255, 255, 255, 0.16);

            line-height: 1.45;
            transition: all .4s ease 0s;
            color: #273b54;
            background-color:#fff;
            border: none;
            @media (max-width: 425px){
                border 1px solid black;
                box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 16%);
            }
            ${sm} {
                &:hover {
                    transform: translateY(-3px);
                }
            }
        }
    }

   

    .score-txt-wrapper {
        padding: 40px 0;

        h1 {
            position: relative;
            color: rgb(22,90,139);
            text-align: center;
            font-size: 34px;
            line-height: 1.3;
            font-weight: ${theme.medium};
            margin: 0 0 24px;
            padding-bottom: 8px;
    
            &:after {
                background-color: rgb(22, 90, 139);
                width: 40px;
                content: '';
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);

                ${sm} {
                    width: 80px;
                }
                ${md} {
                    width: 100px;
                }
            }
        }

        .score-txt {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 168px;
            height: 168px;
            font-size: 80px;
            font-weight: ${theme.semiBold};
            color: ${theme.white};
            background-color: #ed3f3a;
            border: 1px solid #ed3f3a;
            border-radius: 100%;
            box-shadow: inset 0 0 0 3px ${theme.white};
            margin: 0 auto 64px;
        }
    }


    .second-fold {
        margin-bottom: 64px;

        img {
            display: block;
            height: 520px;
            margin: 0 auto 64px;
        }

        h2 {
            color: rgb(0,85,147);
            font-size: 48px;
            font-weight: ${theme.semiBold};
            line-height: 1.33;
            margin: 0 0 32px;

            ${sm} {
                margin-bottom: 40px;
            }
        }

        .btn-wrapper {
            ${xs} {
                text-align: center;
            }
        }
    }
    .third-fold {
        padding-bottom: 150px;

        h3 {
            position: relative;
            color: #3b3b3b;
            font-size: 30px;
            line-height: 1.33;

            padding: 0 10px 20px;
            margin: 0 0 40px;
            letter-spacing: 0.3px;
            

            &:after {
                background-color: #22334a;
                width: 80px;
                height: 6.1px;
                content: '';
                position: absolute;
                bottom: 0;
                left: 54px;
                transform: translateX(-50%);

                ${sm} {
                    width: 80px;
                    left: 53px;
                }
                ${md} {
                    width: 174.2px;
                    height: 6.1px;
                    left: 100px;

                }
            }
        }

        .third-fold-content-wrapper {
            &:not(:last-child) {
                margin: 95px 0px;

            }

            &:nth-child(even) {
               

                .third-fold-content {
                    .left-col {
                        order: 1;
                        
                        

                        
                    }
                }
            }
            
            .third-fold-content {
                display: flex;
               

                .left-col, .right-content-list {
                    width: 100%;

                    
                }
                
                .left-col {
                    padding-left:50px;
                    padding-right:50px;

                    figure {
                        
                        border-radius:32px;
                        position: relative;
                        width: 100%;
                        height: 400px;
                        display: block;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        overflow: hidden;
                        transition: all 0.3s ease 0s;

                        &:hover:before {
                            transform: scale(1.05);
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            right: 0px;
                            bottom: 0px;
                            background: inherit;
                            transition: inherit;
                        }
                    
                      
                    }
                }

                .right-content-list {
                    padding-left: 0px;
                    list-style-type: none;
                    margin-top: 22px;
                   
                    
                    li {
                        position: relative;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 1.75;
                        letter-spacing: 0.16px;
                        color: #3b3b3b;
                        
                        &:not(:last-child) {
                            margin-bottom: 32px;

                            ${md} {
                                margin-bottom: 20px;
                            }
                        }

                        svg {
                            position: absolute;
                            top: 8px;
                            left: 4px;
                        }
                    }
                } 
            }
        }
    }

    .image-with-text {
        padding: 40px 0;
        background-color:#f7f7f7;
        color:#e72369;
        text-align: center;
        ${xs} {
            margin-bottom: 64px;
        }
        
        p {
            font-size: 32px;
             font-weight: ${theme.semiBold};
            line-height: 1.3;
            padding: 0 12px;
            margin-bottom: 0;
        }
    }

    .client-section {
        

        .content {
            p {
                text-align: center;
                font-size: 28px;
                font-weight: ${theme.medium};
                padding:32px;
                margin-bottom:50px;
                @media (max-width: 991px) {
                    
                    
                        font-size:20px;
                        font-weight:bold;
                        
                    
                }
            }

            img {
                width: 100%;
                height: 120px;
                object-fit: contain;

                ${sm} {
                    height: 176px;
                }
                ${md} {
                    height: 240px;
                }
            }
        }
    }

    .product-image {
        position: relative;
        height: 168px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        overflow: hidden;
        transition: all .4s ease 0s;

        &:hover:before {
            transform: scale(1.02);
        }

        &:before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            rext-centeright: 0px;
            bottom: 0px;
            background: inherit;
            transition: inherit;
        }
    }
    .mobile-app-content{
        background-color: #f7f7f7;
        padding: 30px 0px;
       h3{
           font-weight:500;
       }
       h1{
        font-size: 32px;
        line-height: 1.47;
        letter-spacing: 0.32px;
        color: #273b54;
        padding: 0px 155px;
       }

       hr{
        position: relative;
        border: none;
        margin-bottom: 44px;
        &:after {
            background-color: #273b54;
            width: 40px;
            content: '';
            height: 3.8px;
            position: absolute;
            bottom: 0;
            transform: translateX(-50%);

            ${sm} {
                width: 80px;
            }
            ${md} {
                width: 100px;
            }
        }
       }
       p{
           padding: 10px 50px;
           font-size: 18px;
           line-height: 1.7;
       }
       .btn-call{
        border: none;
        border-radius: 35px;
        box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 16%);
        background-color: #22334a;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        margin: 10px;
        padding: 18px 39px;
        ${sm} {
            &:hover {
                transform: translateY(-3px);
            }
        };
    }
    .btn-mobile-app{
        margin: 10px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        border-radius: 35px;
        box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 16%);
        background-color: #22334a;
        color: #fff;
        padding: 18px 22px;
        ${sm} {
            &:hover {
                transform: translateY(-3px);
            }
        };
    }
    }



`;