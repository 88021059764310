import React, { useState, useEffect, Fragment } from "react";
import CryptoJS from "crypto-js";
import { AppImage } from "../../helpers/AppImageConstants";
import Loader from "../common-components/Loader";
import { HomeWrapper } from "./home.styles";

let API_URL = "https://dev-api.vajro.com/v4/survey/form?id=JMN0tsmD&email=";

// const RightArrow = () => {
//   return (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L13.5858 8L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893Z"
//         fill="rgb(47,47,47)"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M16 8C16 7.44772 15.5523 7 15 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H15C15.5523 9 16 8.55229 16 8Z"
//         fill="rgb(47,47,47)"
//       />
//     </svg>
//   );
// };



const Home = () => {
  const [getCampaignData, setCampaignData] = useState({});
  // const [positive, setPositive] = useState(false);
  // const [negative, setNegative] = useState(false);
  // const [neutral, setNeutral] = useState(false);


  const download = () => {
  
    window.open(`https://vajro-growth.herokuapp.com/survey/export?email=${parsed_email_qs.email}`);
    
  }
 
 function parse_query_string(query) {
    let vars = query.split("&");
    let query_string = {};
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        let arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }

  let query_string = window.location.search.substring(1);
  let parsed_email_qs = parse_query_string(query_string);

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${CryptoJS.SHA256(
      API_URL + parsed_email_qs.email,
    ).toString()}`,
  });

  let requestDetails = {
    method: "GET",
    headers,
  };

  useEffect(() => {
    if (Object.keys(getCampaignData).length > 0) {
      return;
    } else {
      fetch(API_URL + parsed_email_qs.email, requestDetails)
        .then((response) => response.json())
        .then((resData) => {
          const dataValue = resData;

          // for (let i = 0; i < dataValue.data.length; i++) {
          //   if (
          //     dataValue.data[i].feedback === "positive" &&
          //     dataValue.data[i].content.length > 0
          //   ) {
          //     setPositive(true);
          //     break;
          //   }
          // }
          // for (let i = 0; i < dataValue.data.length; i++) {
          //   if (
          //     dataValue.data[i].feedback === "negative" &&
          //     dataValue.data[i].content.length > 0
          //   ) {
          //     setNegative(true);
          //     break;
          //   }
          // }
          // for (let i = 0; i < dataValue.data.length; i++) {
          //   if (
          //     dataValue.data[i].feedback === "neutral" &&
          //     dataValue.data[i].content.length > 0
          //   ) {
          //     setNeutral(true);
          //     break;
          //   }
          // }

          setCampaignData(dataValue);
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);
 
  return (
    <Fragment>
      {getCampaignData.data ? (
        <HomeWrapper>
        <div  id="hide-pdf">
          <div className="bg">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 logo">
            <img src={AppImage.logo } alt="logo" />
            </div>
            

            <div className="col-lg-12 col-md-12 col-sm-12 score-text-wrapper  text-center">
             
              <img src={AppImage.score_img } alt="score_bg" />
              <h1>YOU SCORED</h1>
                <span className="score-txt">{getCampaignData.score}</span>
              
              </div>
              
              <div className="col-lg-12 col-md-12  col-sm-12 text-center">
              <div className="score-dynamic-content">
                  {(() => {
                    if (getCampaignData.score > 70) {
                      return (
                        <div>
                          <h2>Well, what are you waiting for!</h2>
                          <p>
                            Your answers suggest that you should have your
                            mobile app already. But, you can still build it now.
                          </p>
                          <p> All it takes is 60 minutes.</p>
                        </div>
                      );
                    } else if (getCampaignData.score > 50 && getCampaignData.score < 70) {
                      return (
                        <div>
                          <h2>
                            Your business is highly likely to succeed with a
                            mobile app.
                          </h2>
                          <p>
                            Your answers suggest that your business can thrive
                            with a mobile app, bringing up to 2x the revenue.{" "}
                          </p>
                          <p>
                            {" "}
                            If you’re not sure where to start, we can help.
                          </p>
                        </div>
                      );
                    } else if (getCampaignData.score > 30 && getCampaignData.score < 50) {
                      return (
                        <div>
                          <h2>
                            Your store may not need a mobile app at this point
                            in time.{" "}
                          </h2>
                          <p>
                            But, you can’t rule it off from your sales strategy.
                            If you want to give it a shot, we can help.
                          </p>
                          <p>
                            From building to publishing to getting your first
                            sale.
                          </p>
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <h2>
                            Your store may not need a mobile app at this point
                            in time.{" "}
                          </h2>
                          <p>
                            But, you can consider building one in the future
                            when you scale. If you want to give it a shot, we
                            can help.
                          </p>
                          <p>
                            From building to publishing to getting your first
                            sale.
                          </p>
                        </div>
                      );
                    }
                  })()}

                  <div className="btn-wrapper">
                    <button className="btn btn-primary" onClick= {download}>
                      Download Report
                    </button>
                  </div>
                </div>
              
            
            </div>
            <div className="col-lg-12 footer-wrapper">
              <h1>Looking For A Mobile App?</h1>
              <div className="border-pink"></div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore. et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur </p>
             <button className="btn btn-call">BOOK A DEMO</button> <button className="btn btn-mobile-app">GET YOUR MOBILE APP NOW</button>
            </div>
            
            
          </div>
          </div>
         



        </div>
        <div className="pdf-generating">
        <div className="main-header">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 logo">
            <img src={AppImage.logo } alt="logo" />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 score-text-wrapper  text-center">
             
              <img src={AppImage.score_img } alt="score_bg" />
              <h1>YOU SCORED</h1>
                <span className="score-txt">{getCampaignData.score}</span>
              
              </div>
              {/* <div className="col-lg-6 col-md-6  col-sm-6 iphone">
              <img src={AppImage.iphone_two } alt="iphone_two" />
            </div> */}
              <div className="col-lg-12 col-md-12 col-sm-12 space text-center">
              <div className="score-dynamic-content">
                  {(() => {
                    if (getCampaignData.score > 70) {
                      return (
                        <div>
                          <h2>Well, what are you waiting for!</h2>
                          <p>
                            Your answers suggest that you should have your
                            mobile app already. But, you can still build it now.
                          </p>
                          <p> All it takes is 60 minutes.</p>
                        </div>
                      );
                    } else if (getCampaignData.score > 50 && getCampaignData.score < 70) {
                      return (
                        <div>
                          <h2>
                            Your business is highly likely to succeed with a
                            mobile app.
                          </h2>
                          <p>
                            Your answers suggest that your business can thrive
                            with a mobile app, bringing up to 2x the revenue.{" "}
                          </p>
                          <p>
                            {" "}
                            If you’re not sure where to start, we can help.
                          </p>
                        </div>
                      );
                    } else if (getCampaignData.score > 30 && getCampaignData.score < 50) {
                      return (
                        <div>
                          <h2>
                            Your store may not need a mobile app at this point
                            in time.{" "}
                          </h2>
                          <p>
                            But, you can’t rule it off from your sales strategy.
                            If you want to give it a shot, we can help.
                          </p>
                          <p>
                            From building to publishing to getting your first
                            sale.
                          </p>
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <h2>
                            Your store may not need a mobile app at this point
                            in time.{" "}
                          </h2>
                          <p>
                            But, you can consider building one in the future
                            when you scale. If you want to give it a shot, we
                            can help.
                          </p>
                          <p>
                            From building to publishing to getting your first
                            sale.
                          </p>
                        </div>
                      );
                    }
                  })()}

                  <div className="btn-wrapper">
                    <button className="btn btn-primary" onClick= {download}>
                    Start Your 60-Day Free Trial
                    </button>
                  </div>
                </div>
              
            
            </div>
           
            
            
          </div>
        </div>
       
          
        <div className="third-fold">
            <div className="container lg-container xs-fullwidth">
              {
                <div className="third-fold-content-wrapper">
                  

                  <div className={`third-fold-content`}>
                    <div className="left-col">
                      
                      
                    <figure
                        style={{
                          backgroundImage: `url('${AppImage.oil}')`,
                        }}
                      />
                    </div>
                    <div className="right-content-list">
                    <h3>
                    Here are a few other things we noticed about your business!
                  </h3>
                    <ul >
                      {(getCampaignData.data.slice(0, 2) || []).map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {item.feedback &&
                            item.content.length > 0 ? (
                              <li>
                                {item.content}
                              </li>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </ul>
                    </div>
                  </div>
                </div>
             }
            


            {
                <div className="third-fold-content-wrapper">
                  

                  <div className={`third-fold-content`}>
                    <div className="left-col">
                      
                      
                    <figure
                        style={{
                          backgroundImage: `url('${AppImage.vaping}')`,
                        }}
                      />
                    </div>
                    <div className="right-content-list">
                    <h3>
                    Here are a few other things we noticed about your business!
                  </h3>
                    <ul >
                      {(getCampaignData.data.slice(2, 4) || []).map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {item.feedback &&
                            item.content.length > 0 ? (
                              <li>
                                {item.content}
                              </li>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </ul>
                    </div>
                  </div>
                </div>
             }



{
                <div className="third-fold-content-wrapper">
                  

                  <div className={`third-fold-content`}>
                    <div className="left-col">
                      
                      
                    <figure
                        style={{
                          backgroundImage: `url('${AppImage.leaf}')`,
                        }}
                      />
                    </div>
                    <div className="right-content-list">
                    <h3>
                    Here are a few other things we noticed about your business!
                  </h3>
                    <ul >
                      {(getCampaignData.data.slice(4, 6) || []).map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {item.feedback &&
                            item.content.length > 0 ? (
                              <li>
                                {item.content}
                              </li>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </ul>
                    </div>
                  </div>
                </div>
             }




{
                <div className="third-fold-content-wrapper">
                  

                  <div className={`third-fold-content`}>
                    <div className="left-col">
                      
                      
                    <figure
                        style={{
                          backgroundImage: `url('${AppImage.oil}')`,
                        }}
                      />
                    </div>
                    <div className="right-content-list">
                    <h3>
                    Here are a few other things we noticed about your business!
                  </h3>
                    <ul >
                      {(getCampaignData.data.slice(6, 8) || []).map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {item.feedback &&
                            item.content.length > 0 ? (
                              <li>
                                {item.content}
                              </li>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </ul>
                    </div>
                  </div>
                </div>
             }





{
                <div className="third-fold-content-wrapper">
                  

                  <div className={`third-fold-content`}>
                    <div className="left-col">
                      
                      
                    <figure
                        style={{
                          backgroundImage: `url('${AppImage.vaping}')`,
                        }}
                      />
                    </div>
                    <div className="right-content-list">
                    <h3>
                    Here are a few other things we noticed about your business!
                  </h3>
                    <ul >
                      {(getCampaignData.data.slice(8, 10) || []).map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {item.feedback &&
                            item.content.length > 0 ? (
                              <li>
                                {item.content}
                              </li>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </ul>
                    </div>
                  </div>
                </div>
             }
              {/* {negative ? (
                <div className="third-fold-content-wrapper">
                  

                  <div className={`third-fold-content`}>
                    <div className="left-col">
                      <figure
                        style={{
                          backgroundImage: `url('${AppImage.negative_image}')`,
                        }}
                      />

                    </div>
                    <div className="right-content-list">
                    <h3>However, you should consider working on a few things.</h3>
                    <ul >
                      {(getCampaignData.data || []).map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {item.feedback === "negative" &&
                            item.content.length > 0 ? (
                              <li>
                                 {item.content}
                              </li>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </ul>
                    </div>
                  </div>
                </div>
              ) : null} */}

              {/* {neutral ? (
                <div className="third-fold-content-wrapper">
                  

                  <div className={`third-fold-content`}>
                    <div className="left-col">
                      <figure
                        style={{
                          backgroundImage: `url('${AppImage.positive_image5}')`,
                        }}
                      />
                    </div>
                    <div className="right-content-list">
                    <h3>
                    We also noticed some other things that you should consider.
                  </h3>
                    <ul >
                      {(getCampaignData.data || []).map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {item.feedback === "neutral" &&
                            item.content.length > 0 ? (
                              <li>
                                {item.content}
                              </li>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </ul>
                  </div>
                  </div>
                </div>
              ) : null} */}
            </div>
          </div>

          {/* <div className="image-with-text">
            <div className="container lg-container ">
              <p>
                Over 750 Shopify stores trust Vajro to engage with their
                customers, drive revenue and improve engagement
              </p>
            </div>
          </div>

          <div className="client-section section-space-ptb_72">
            <div className="content">
              <p>
              Here's How Pretty Hot Mess LLC, A Boutique Store That Uses Vajro Has Performed!
              </p>

              <img
                src={AppImage.client_performed_image}
                alt="client_performed"
              />
            </div>
          </div>
            <img  src={AppImage.client_img} alt="client_img"/> */}

          <div className="mobile-app-content section-space-ptb_72 text-center">
            <div className="container bg">
              {/* <h3 >
                Still not sure if you need a mobile app?</h3>  */}
                <h1 className="mb-3">Would you like to learn more about how mobile apps function and what kind a features we offer?</h1>
                <hr/>
{/* 
              <p>
              <b>No coding involved, no credit card required. You could quadruple sales from your Shopify store with a well designed mobile app.</b> In light of the ongoing
pandemic,
              </p>
              <p >
             <b> Vajro’s world class support team</b> will assist you on setting up and launching your mobile app remotely! We hope the extended trial period gives your store
enough time to set up your app, promote downloads and boost revenue!
              </p> */}

              
              <button className="btn btn-call">CALL US NOW</button> <button className="btn btn-mobile-app">GET YOUR MOBILE APP NOW</button>
              
            </div>
          </div>

          </div>
        </HomeWrapper>
      ) : (
        <Loader show />
      )}
    </Fragment>
  );
};

export default Home;
