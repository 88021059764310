import styled from "styled-components";

export const HomeWrapper = styled.div`
  .cbd {
    #hide-pdf {
  
      font-family: 'Montserrat', sans-serif;
      .bg {
        
        background-image: url("https://res.cloudinary.com/vajrohq/image/upload/v1657610258/admin_console/vajro-website/quiz/Group_8204.png");
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        height: 100vh;

        .header-content {
          font-size: 40px;
          @media (max-width: 767px) {
            font-size: 25px;
          }
        }
        h1 {
          font-size: 80px;
          padding-bottom: 25px;
          @media (max-width: 767px) {
            font-size: 40px;
          }
          span {
            text-shadow: 0 0 3px #fff, 0 0 5px #fff;
            color: #5e8c8d;
          }
        }
        p {
          width: 50%;
          @media (max-width: 767px) {
            width: 100%;
          }
          .text-bold {
            font-weight: bold;
          }
        }
        .download-btn {
          background-color: #fff;
          border-radius: 8px;
          color: #f62369;
          font-weight: bold;
          margin-top: 20px;
          padding: 12px 24px;
        }
      }
    }

    .pdf-generating {
      display: none;
      font-family: 'Montserrat', sans-serif;
      .bg {
        background-image: url("https://res.cloudinary.com/vajrohq/image/upload/v1658129602/admin_console/vajro-website/quiz/MacBook_Pro_14__-_2.png");
        background-repeat: no-repeat;
        width: 100%;
        height: 700px;
        background-size: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0px 50px;
        position:relative;
        .header-content {
          font-size: 40px;
        }
        .score-txt {
          position: absolute;
          font-size: 120px;
          top: 150px;
          left: 240px;
          color: #f62369;
        }
        .score-dynamic-content{
          margin-top: 200px;

        
        }
       
        }
      }

      .third-fold {
        padding-bottom: 150px;

        h3 {
          position: relative;
          color: #3b3b3b;
          font-size: 25px;
          line-height: 1.33;
          letter-spacing: 0.3px;
          font-weight: bold;
        }
     

        .third-fold-content-wrapper {
            padding:75px 0 75px 0;
          &:not(:last-child) {
            margin: 95px 0px;
          }

          &:nth-child(even) {
            .third-fold-content {
              .left-col {
                order: 1;
              }
            }
          }

          .third-fold-content {
            display: flex;
            position: relative;
            align-items: center;
          
            .background{
              position: absolute;
              background-color: #fff6f6;
              top: -50px;
              bottom: -40px;
              right: -80px;
              left: 302px;
              
            }
            .background2{
              position: absolute;
              background-color: #fff6f6;
              top: -50px;
              bottom: -40px;
              left: -80px;
              right: 302px;
              
            }
            .left-col {
              width: 100%;
            }

            .right-content-list {
              width: 100%;
             
            }

            .left-col {
              padding-left: 50px;
              padding-right: 50px;

              figure {
                position: relative;
                width: 100%;
                height: 500px;
                display: block;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                overflow: hidden;
                transition: all 0.3s ease 0s;

                &:hover:before {
                  transform: scale(1.05);
                }

                &:before {
                  content: "";
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  right: 0px;
                  bottom: 0px;
                  background: inherit;
                  transition: inherit;
                }
              }
            }

            .right-content-list {
              padding-left: 0px;
              list-style-type: none;

              ul {
                padding-left: 0;
                li {
                  position: relative;
                  font-size: 17px;
                  font-weight: 500;
                  line-height: 1.75;
                  letter-spacing: 0.16px;
                  color: #3b3b3b;
                  list-style: none;
                  &:not(:last-child) {
                    margin-bottom: 32px;

                  
                  }
                }
              }
            }
          }
        }
      }

      .footer-content {
        text-align: center;
        padding-top: 40px;
        background-color: #f62369;
        border-radius: 30px 30px 0 0; 
    margin-bottom: 20px;
    color:#fff;
        h3 {
          padding-bottom: 20px;
        }
        p {
          padding-bottom: 20px;
        }
        .pink-btn {
          margin-top: 10px;
          margin-bottom: 30px;
          color: #f62369;
          background-color: #fff;
          paddiing: 12px 24px;
          font-weight:bold;
        }
      }
     
    }
  }
`;
