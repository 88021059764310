export const theme = {
  // FONT
  primaryFont: `'Montserrat', sans-serif`,

  // FONT-WEIGHTS
  regular: 400, // default
  medium: 500,
  semiBold: 600,
  bold: 700,

  // FONT-SIZES
  fsMini: '6px',
  fsBase: '14px', // default font size
  fsMedium: '16px',
  fsLarge: '18px',
  fsXlarge: '20px',

  // LINE-HEIGHTS
  lineHeight1: 1,
  lineHeight1_09: 1.09,
  lineHeight1_2: 1.2, // default
  lineHeight1_4: 1.4,

  // COLORS
  baseColor: '#595959', 
  white: '#FFFFFF',
  black: '#000000', // default

  // Z-INDEX
  zIndex1: 1,
  zIndex2: 2,
  zIndex3: 3,
  zIndex4: 4,
  zIndex5: 5,
  zIndex6: 6,
  zIndex7: 7,
  zIndex8: 8,
  zIndex9: 9,
  zIndex10: 10
};
