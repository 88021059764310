import LogoImg from '../images/home/logo.png';
import LogoWhite from '../images/home/logo_white.png';
import IphoneFrameImg from '../images/home/iphone-frame.png';
import PositiveImg1 from '../images/home/positive-image1.png';
import PositiveImg2 from '../images/home/positive-image2.png';
import PositiveImg3 from '../images/home/positive-image3.png';
import PositiveImg4 from '../images/home/positive-image4.jpg';
import PositiveImg5 from '../images/home/positive-image5.jpg';
import Leaf from '../images/home/leaf.png';
import Oil from '../images/home/oil.png';
import Vaping from '../images/home/vaping.png';
import NegativeImg from '../images/home/negative-image.jpeg';
import ClientPerformedImg from '../images/home/client_performed_image.png';
import VajroBgImg from '../images/home/vajro_bg_image.jpg';
import BgArtboard from '../images/home/bg_artboard.jpg';
import BgBlueCorners from '../images/home/bg_blue_corners.png';
import ImgWithText from '../images/home/image_with_text.jpg';
import pinkBackground from '../images/home/background.png';
import QuizBackground from '../images/home/quiz_background.png';
import QuizBackgroundPdf from '../images/home/quiz_background_pdf.png';
import score from '../images/home/score_img.png';
import QuizScore from '../images/home/quiz_score_img.png';
import iphone from '../images/home/iphone_two.png'
import clienImg from '../images/home/client_img.jpg'
import pdfbackgrond from '../images/home/background_pdf.png'

export const AppImage = {
    iphone_two: iphone,
    score_img: score,
    logo: LogoImg,
    logoWhite: LogoWhite,
    background: pinkBackground,
    quiz_background: QuizBackground,
    quiz_background_pdf: QuizBackgroundPdf,
    iphone_frame_img: IphoneFrameImg,
    positive_image1: PositiveImg1,
    positive_image2: PositiveImg2,
    positive_image3: PositiveImg3,
    positive_image4: PositiveImg4,
    positive_image5: PositiveImg5,
    quiz_score_img: QuizScore,
    leaf: Leaf,
    oil: Oil,
    vaping: Vaping,
    negative_image: NegativeImg,
    client_performed_image: ClientPerformedImg,
    vajro_bg_image: VajroBgImg,
    image_with_text: ImgWithText,
    bg_blue_corners: BgBlueCorners,
    bg_artboard: BgArtboard,
    client_img: clienImg,
    background_pdf: pdfbackgrond
};
  